@tailwind base;
@tailwind components;
@tailwind utilities;

/* disables box shadow border around input field */
input {
  --tw-ring-shadow: 0 0 #000 !important;
}

body {
  overscroll-behavior-y: none;
}

.pulse {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  70% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #2d3748;
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #4a5568;
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #718096;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
    --primary: 240 5.9% 10%;
    --primary-foreground: 0 0% 98%;
    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 5.9% 15%;
    --input: 240 5.9% 90%;
    --ring: 240 5.9% 10%;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --radius: 0.5rem;
  }
  .dark {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;
    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

/* Neural Web Animation */
.neural-web {
  animation: pulse 4s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.4;
  }
}

/* Add random movement to the neural web points */
.neural-web svg {
  animation: float 15s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translateY(0) translateX(0) rotate(0);
  }
  33% {
    transform: translateY(-10px) translateX(5px) rotate(1deg);
  }
  66% {
    transform: translateY(5px) translateX(-5px) rotate(-1deg);
  }
  100% {
    transform: translateY(0) translateX(0) rotate(0);
  }
}

/* Add a subtle glow effect */
.neural-web::after {
  content: "";
  position: absolute;
  inset: 0;
  background: radial-gradient(
    circle at center,
    rgba(59, 130, 246, 0.1) 0%,
    transparent 70%
  );
  animation: glow 8s ease-in-out infinite alternate;
}

@keyframes glow {
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.7;
  }
}

/* Turbulence Animation */
@keyframes turbulence {
  0% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(1.1) rotate(1deg);
  }
  100% {
    transform: scale(1) rotate(0deg);
  }
}

#turbulence feTurbulence {
  animation: turbulence 20s infinite;
}

/* Add a subtle pulse to the shapes */
@keyframes shapePulse {
  0%,
  100% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.4;
  }
}

#square-clip {
  animation: shapePulse 8s infinite;
}

/* Watercolor Animation */
@keyframes watercolor {
  0% {
    transform: scale(1) rotate(0deg);
    filter: url(#turbulence) brightness(1);
  }
  25% {
    transform: scale(1.02) rotate(0.5deg);
    filter: url(#turbulence) brightness(1.05);
  }
  50% {
    transform: scale(1.01) rotate(-0.5deg);
    filter: url(#turbulence) brightness(0.95);
  }
  75% {
    transform: scale(0.99) rotate(0.25deg);
    filter: url(#turbulence) brightness(1);
  }
  100% {
    transform: scale(1) rotate(0deg);
    filter: url(#turbulence) brightness(1);
  }
}

/* Apply the animation to the watercolor group */
#turbulence {
  animation: watercolor 20s ease-in-out infinite;
}

/* Smooth path morphing */
@keyframes morphPaths {
  0%,
  100% {
    d: path("M100,100 Q400,50 700,200 T800,400 T100,500 T100,100");
  }
  50% {
    d: path("M100,150 Q450,100 650,250 T800,450 T150,450 T100,150");
  }
}

/* Subtle color shift */
@keyframes colorShift {
  0%,
  100% {
    opacity: 0.7;
  }
  50% {
    opacity: 0.5;
  }
}

g[filter="url(#turbulence)"] {
  animation: colorShift 10s ease-in-out infinite;
}

/* Remove old animations */
.neural-web,
.neural-web svg,
.neural-web::after,
#square-clip {
  animation: none;
}
